<div class="link">
  @if (!inProgress) {
    <a [href]="downloadLinkAuth">{{ id }}</a>
  } @else {
    <span>{{ id.substring(1) }}</span>
  }
</div>
<div class="stack">
  <div class="info">
    @if (inProgress) {
      <span i18n>in progress...</span>
    }
  </div>
  <div class="actions">
    <a [routerLink]="['/ref', downloadLink]" i18n>ref</a>
    <a *ngIf="store.account.admin && !restoring && !inProgress"
       class="fake-link"
       (click)="restoring = true" i18n>restore</a>
    <span *ngIf="restoring" class="restore-confirm">are you sure?
      <a class="fake-link" (click)="restore()">yes</a> /
      <a class="fake-link" (click)="restoring = false" i18n>no</a>
    </span>
    <a *ngIf="store.account.admin && !deleting"
       class="fake-link"
       (click)="deleting = true" i18n>delete</a>
    <span *ngIf="deleting" class="delete-confirm" i18n>are you sure?
      <a class="fake-link" (click)="delete()">yes</a> /
      <a class="fake-link" (click)="deleting = false">no</a>
    </span>
  </div>
</div>

@for (e of serverError; track e) {
  <div class="error">{{ e }}</div>
}
