<app-loading *ngIf="!messages || loadingPrev"></app-loading>
<a *ngIf="messages && !loadingPrev" class="load-more fake-link" (click)="loadPrev()">load prev</a>
<cdk-virtual-scroll-viewport class="messages"
                             [class.scroll-lock]="scrollLock"
                             [itemSize]="itemSize"
                             [style.height.px]="containerHeight"
                             (scrolledIndexChange)="onScroll($event)">
  <app-chat-entry *cdkVirtualFor="let ref of messages; let index = index; let odd = odd"
                  [ref]="ref"
                  [class.odd]="odd"
                  (click)="scrollLock = index"
                  [focused]="scrollLock === index"></app-chat-entry>
</cdk-virtual-scroll-viewport>
<div class="spacer"></div>
<div class="input-box">
  <button type="button"
          class="refresh"
          [title]="'last polled ' + lastPoll.toRelative()"
          (click)="refresh()" i18n>🔄</button>
  <input type="text"
         i18n-placeholder
         placeholder="chat..."
         enterkeyhint="send"
         size="10"
         [(ngModel)]="addText"
         (keydown)="$event.key === 'Enter' && add() || true"
         appAutofocus>
  <button type="button"
          class="add"
          i18n-title
          title="Send"
          (click)="add()" i18n>⏎</button>
</div>
<span class="hints">
  <ng-container *ngIf="admin.getPlugin('plugin/latex')">
    <input id="latex" type="checkbox" [(ngModel)]="latex">
    <label for="latex" i18n>
      LaTeX plugin
      <sup><a target="_blank" href="https://katex.org/docs/supported.html">help</a></sup>
    </label>
  </ng-container>
</span>
<div class="list-container">
  <ng-container *ngFor="let ref of errored">
    <button type="button" class="retry" (click)="retry(ref)" i18n>retry</button>
    <app-chat-entry [ref]="ref" [loading]="false"></app-chat-entry>
  </ng-container>
  <app-chat-entry *ngFor="let ref of sending" [ref]="ref"></app-chat-entry>
</div>
