<ng-container *mobxAutorun>
  <span class="back-button" (click)="back()">🔙️</span>
  <div class="subs">
    <a [routerLink]="['/tags', store.view.noQuery]" [class.current-page]="store.view.tags" [queryParams]="{ home: store.view.current === 'home' || null }" i18n>tags</a>
    &bullet; <a routerLink="/home" [class.current-page]="store.view.current === 'home' && !store.view.forYou" i18n>home</a>
    <ng-container *ngIf="admin.getTemplate('user') && store.account.signedIn">
      &bullet; <a routerLink="/home" [queryParams]="{ forYou : true }" routerLinkActive="current-page" i18n>for you</a>
    </ng-container>
    &bullet; <a [routerLink]="'/tag/' + (store.account.origin || '*')" routerLinkActive="current-page" i18n>local</a>
    &bullet; <a routerLink="/tag/@*" routerLinkActive="current-page" i18n>all</a>
    <ng-container *ngIf="bookmarkExts.length; else bookNoExts">
      <ng-container *ngFor="let b of bookmarkExts; let i = index">
        &bullet; <a [routerLink]="['/tag', store.account.bookmarks[i]]" [appTitle]="b" routerLinkActive="current-page">{{ b.name || store.account.bookmarks[i] }}</a>
      </ng-container>
    </ng-container>
    <ng-template #bookNoExts>
      <ng-container *ngFor="let t of store.account.bookmarks">
        &bullet; <a [routerLink]="['/tag', t]" routerLinkActive="current-page">{{ t }}</a>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="subExts.length; else subNoExts">
      <ng-container *ngFor="let sub of subExts; let i = index">
        &bullet; <a [routerLink]="['/tag', store.account.subs[i]]" [appTitle]="sub" routerLinkActive="current-page">{{ sub.name || store.account.subs[i] }}</a>
      </ng-container>
    </ng-container>
    <ng-template #subNoExts>
      <ng-container *ngFor="let t of store.account.subs">
        &bullet; <a [routerLink]="['/tag', t]" routerLinkActive="current-page">{{ t }}</a>
      </ng-container>
    </ng-template>
  </div>
  <span *ngIf="!store.submit.empty" class="drafts" routerLink="/submit/upload" i18n-title title="Unsaved Drafts" i18n>🗂️</span>
  <div class="light-toggle"
     (click)="themes.toggle(store.hotkey)">
    <div class="moon" [class.lights-on]="!store.darkTheme" [class.lights-off]="store.darkTheme" i18n>🌙️</div>
    <div class="light" [class.lights-on]="!store.darkTheme" [class.lights-off]="store.darkTheme" i18n>💡️</div>
  </div>
</ng-container>
