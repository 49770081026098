<div class="md"
     markdown
     clipboard
     [disableSanitizer]="disableSanitizer"
     [appMdPost]="postProcessMarkdown"
     [origin]="origin"
     [katex]="!!plugins?.includes('plugin/latex')"
     [katexOptions]="katexOptions"
     mermaid
     [mermaidOptions]="$any({ theme: store.darkTheme ? 'dark' : 'default' })"
     [data]="value"
     (ready)="onReady()"></div>
