<form class="form" [formGroup]="originForm" (ngSubmit)="deleteOrigin()">
  <span></span>
  <h2>Clear Origin</h2>

  <label>Origin:</label>
  <input type="text" [pattern]="originPattern" formControlName="origin">

  <label>Older than:</label>
  <input type="datetime-local" step="1" formControlName="olderThan">

  <span><!-- Buttons --></span>
  <span class="buttons right">
    <button type="submit" [disabled]="submitted && !originForm.valid" i18n>Delete</button>
  </span>
</form>

<div class="buttons">
  @if (store.account.admin) {
    <button type="button"
            (click)="backup()"
            i18n-title
            title="Create Backup"
            class="backup" i18n>+ backup</button>
  }

  @if (store.account.mod) {
    <button type="button"
            (click)="backfill()"
            i18n-title
            title="Backfill Metadata"
            class="backfill" i18n>♻️ backfill</button>
  }

  @if (!uploading) {
    <button
      type="button"
      (click)="uploadFile.click()"
      i18n-title
      title="Upload Backup"
      class="upload" i18n>+ upload</button>
  } @else {
    <app-loading></app-loading>
  }
  <input #uploadFile
         type="file"
         class="upload"
         (change)="upload(uploadFile?.files!)">
</div>

@for (e of serverError; track e) {
  <span><!-- Unexpected Error --></span>
  <div class="error">{{ e }}</div>
}

<app-backup-list [list]="list"
                 [origin]="origin"></app-backup-list>
