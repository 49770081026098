<app-editor [control]="comment"
            [tags]="ref.tags"
            (syncTags)="editorTags = $event"
            [autoFocus]="true"></app-editor>
@for (e of serverError; track e) {
  <span><!-- Unexpected Error --></span>
  <div class="error">{{ e }}</div>
}
<button type="button" [disabled]="editing" (click)="save()" i18n>save</button>
@if (editing) {
  <app-loading [inline]="true"></app-loading>
} @else {
  <button type="button" (click)="cancel()" i18n>cancel</button>
}
