<a class="fake-link" (click)="editing = !editing">
  <ng-content></ng-content>
</a>
@if (editing) {
  <span class="inline-password">
    <input #inlinePassword
           (keydown)="$event.key === 'Enter' && save(inlinePassword) || true"
           (input)="inlinePassword.value = inlinePassword.value.toLowerCase()"
           type="password"
           appAutofocus>
    <button type="button" (click)="save(inlinePassword)" i18n>+</button>
  </span>
} @else if (acting) {
  <app-loading [inline]="true"></app-loading>
}
