<select #create
        class="big"
        (input)="addSort($any(create.value))"
        i18n-title title="Sort">
  <option class="unselected">🔼️ sort</option>
  <option *ngFor="let s of allSorts" [value]="s.value" [title]="s.title || ''">{{ s.label || s.value }}</option>
</select>
<span class="controls" *ngFor="let sort of sorts; let i=index">
  <select id="sort-{{ i }}" [ngModel]="sortCol(sort)" (ngModelChange)="setSortCol(i, $event)" [title]="title(sortCol(sort))">
    <option *ngFor="let s of allSorts" [value]="s.value" [title]="s.title || ''">{{ s.label || s.value }}</option>
  </select>
  <button type="button" *ngIf="sortDir(sort) === 'DESC'"(click)="setSortDir(i, 'ASC')" i18n>🔽️</button>
  <button type="button" *ngIf="sortDir(sort) === 'ASC'" (click)="setSortDir(i, 'DESC')" i18n>🔼️</button>
  <button type="button" *ngIf="sorts.length > 1" (click)="removeSort(i)" i18n>&ndash;</button>
</span>
