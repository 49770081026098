<a class="fake-link" (click)="editing = !editing">
  <ng-content></ng-content>
</a>
@if (editing) {
  <span class="inline-url">
    <input #inlineUrl
           (keydown)="$event.key === 'Enter' && save(inlineUrl) || true"
           (input)="inlineUrl.value = inlineUrl.value.toLowerCase()"
           [value]="value"
           type="url"
           [pattern]="uriRegex"
           appAutofocus>
    <button type="button" (click)="save(inlineUrl)" i18n>+</button>
  </span>
} @else if (acting) {
  <app-loading [inline]="true"></app-loading>
}
