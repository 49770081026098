@if (page) {
  @for (ref of page.content; track ref.origin + '@' + ref.url) {
    <app-kanban-card cdkDrag
                     [hideSwimLanes]="hideSwimLanes"
                     [cdkDragData]="ref"
                     [cdkDragStartDelay]="pressToUnlock ? 251 : 0"
                     [pressToUnlock]="pressToUnlock"
                     [ref]="ref"
                     [ext]="ext"
                     (copied)="copy($event)"></app-kanban-card>
  }
  @for (text of adding; track text) {
    <app-kanban-card [ref]="{ url: '', title: text }"
                     [ext]="ext"></app-kanban-card>
  }
} @else {
  <app-loading></app-loading>
}
<div class="spacer"></div>
@if (hasMore) {
  <a class="fake-link no-select load-more"
     (click)="loadMore()" i18n>load more @if (more) {<span class="more">({{ more }})</span>}</a>
}
<input type="text"
       enterkeyhint="send"
       i18n-placeholder
       placeholder="Add..."
       [(ngModel)]="addText"
       [style.display]="addText ? 'block' : 'none'"
       (keydown)="$event.key === 'Enter' && add() || true"
       (blur)="add()">
