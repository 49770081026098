<ng-container [formGroup]="group">
  <label for="tag" i18n>Tag:</label>
  <input id="tag" type="text" formControlName="tag" [attr.disabled]="true">

  <label for="title" i18n>Name:</label>
  <input id="title"
         type="text"
         [formControl]="name"
         (blur)="validate($any($event.target))">

  <label for="generateMetadata" i18n>Generate metadata:</label>
  <div>
    <input id="generateMetadata" type="checkbox" formControlName="generateMetadata">
  </div>

  <label for="config" i18n>Config:</label>
  <app-json id="config"
            fieldName="config"
            [group]="group"></app-json>
  @if (configErrors.length) {
    <span><!-- Errors --></span>
    @for (e of configErrors; track e) {
      <div>
        <span class="error">{{ e }}</span>
      </div>
    }
  }

  <label for="defaults" i18n>Defaults:</label>
  <app-json id="defaults"
            fieldName="defaults"
            [group]="group"></app-json>
  @if (defaultsErrors.length) {
    <span><!-- Errors --></span>
    @for (e of defaultsErrors; track e) {
      <div>
        <span class="error">{{ e }}</span>
      </div>
    }
  }

  <label for="schema" i18n>Schema:</label>
  <app-json id="schema"
            fieldName="schema"
            [group]="group"></app-json>
  @if (schemaErrors.length) {
    <span><!-- Errors --></span>
    @for (e of schemaErrors; track e) {
      <div>
        <span class="error">{{ e }}</span>
      </div>
    }
  }
</ng-container>
