<ng-container *mobxAutorun>
  <div class="row">
    @if (showToggle) {
      <div class="toggle" (click)="expanded = !expanded">☰</div>
    }
    @if (store.view.type && (!home || store.view.search || store.view.filter.length || query.page?.content?.length)) {
      <div class="wide">
        <app-search [class.centered]="!expanded || !store.view.settings && !store.view.tags"></app-search>
        @if (expanded && (store.view.settings || store.view.tags)) {
          <div class="show-remotes">
            <input id="show-remotes" #sr type="checkbox" [checked]="store.view.showRemotes" (input)="showRemotes = sr.checked">
            <label for="show-remotes" i18n>show remotes</label>
          </div>
        }
      </div>
    }
  </div>
  @if (expanded) {
    @if (store.view.type && (!home || store.view.search || store.view.filter.length || query.page?.content?.length)) {
      @if (store.view.tag) {
        <app-query [query]="tag" class="breadcrumbs"></app-query>
      }
      <div class="row mini-col reverse">
        <app-filter [type]="store.view.type!" [activeExts]="activeExts"></app-filter>
        <app-sort [type]="store.view.type!"></app-sort>
      </div>
    }
    <app-debug></app-debug>
    @if (store.view.type) {
      <app-bulk [type]="store.view.type!"
                [viewExt]="ext"
                [activeExts]="activeExts"></app-bulk>
    }
  }
  <span class="button-bar">
    @if (!user && canAddTag && !plugin?.config?.reply && store.account.user && (store.view.type === 'ref' || store.view.current === 'ref/summary')) {
      @if (plugin?.config?.submitText || rootConfig?.submitText) {
        <div class="submit-button" routerLink="/submit/text" tabindex="0"
             [queryParams]="{ tag: addTags, thumbnail: defaultThumbnail }">
        <span i18n-title title="Submit" i18n>📝️</span> <label i18n>Submit
        &nbsp;
        &nbsp;
          {{ plugin?.name || '' }}</label>
      </div>
      } @else if (plugin?.config?.submit && plugin?.config?.genId) {
        <div class="submit-button" routerLink="/submit/web" tabindex="0"
             [queryParams]="{ tag: addTags, url: genUrl, thumbnail: defaultThumbnail }">
          <span i18n-title title="Submit" i18n>📝️</span> <label i18n>Submit
          &nbsp;
          &nbsp;
          {{ plugin!.config!.submit }}</label>
        </div>
      } @else {
        <div class="submit-button" routerLink="/submit" tabindex="0"
             [queryParams]="{ tag: addTags, thumbnail: defaultThumbnail }">
          <span i18n-title title="Submit" i18n>📝️</span> <label i18n>Submit
          &nbsp;
          &nbsp;
          {{ plugin?.config?.submit || '' }}</label>
        </div>
      }
    }
    @if (store.view.current === 'tag' && store.account.user && (home || store.account.tag === tag)) {
      <div class="submit-button" routerLink="/submit/dm"  tabindex="0"
           [queryParams]="{ to: store.account.tag }">
        <span i18n-title title="Notes" i18n>✍️</span> <label i18n>Notes</label>
      </div>
    }
    @if (store.account.tag !== tag && messages) {
      @if (dms.length) {
        <div class="submit-button" routerLink="/submit/dm"  tabindex="0"
             [queryParams]="{ to: dms, tag: null }">
          <span i18n-title title="Direct Message" i18n>✉️</span> <label i18n>Message</label>
        </div>
      } @else if (mailPlugin) {
        <div class="submit-button" routerLink="/submit/dm"  tabindex="0"
             [queryParams]="{ to: tag, tag: mailPlugin.tag }">
          <span i18n-title title="Direct Message" i18n>✉️</span> <label i18n>Message
          &nbsp;
          &nbsp;
          {{ mailPlugin.config?.submit || '' }}</label>
        </div>
      } @else if (modmail || user) {
        <div class="submit-button" routerLink="/submit/dm"  tabindex="0"
             [queryParams]="{ to: tag, tag: null }">
          <span i18n-title title="Direct Message" i18n>✉️</span> <label i18n>Message</label>
        </div>
      }
    }
    @if (store.account.mod && store.view.current === 'settings/user') {
      <div class="submit-button" routerLink="/user" tabindex="0">
        <span i18n-title title="Create User" i18n>🧑️</span> <label i18n>Create Permissions</label>
      </div>
    }
    @if (writeAccess && user && store.view.current === 'tag') {
      <div class="submit-button" [routerLink]="['/user', tag]" tabindex="0">
        <span i18n-title title="Edit User" i18n>🧑️</span> <label i18n>Edit Permissions</label>
      </div>
    }
    @if (writeAccess && store.view.type === 'ext') {
      <div class="submit-button" [routerLink]="['/ext', store.view.template]" tabindex="0">
        <span i18n-title title="Extend Tag" i18n>🎨️</span> <label i18n>Extend Tag
        &nbsp;
        &nbsp;
        {{ template?.name || '' }}</label>
      </div>
    }
    @if (homeWriteAccess) {
      <div class="submit-button" [routerLink]="['/ext', 'home']" tabindex="0">
        <span i18n-title title="Edit Home Page" i18n>🏡️</span> <label i18n>Home Page</label>
      </div>
    }
    @if (store.view.type === 'ext' && !store.view.template) {
      <div class="submit-button" [routerLink]="['/ext']" tabindex="0">
        <span i18n-title title="Extend Tag" i18n>🎨️</span> <label i18n>Extend Tag</label>
      </div>
    }
    @if (local && writeAccess && store.view.noQuery) {
      <div class="submit-button" [routerLink]="['/ext', tag]" tabindex="0">
        <span i18n-title title="Edit Tag" i18n>🎨️</span> <label i18n>Edit Tag</label>
      </div>
    }
    @if (store.account.user && ext && (!local || !writeAccess) && store.view.noQuery) {
      <div class="submit-button" [routerLink]="['/tags', tag]" tabindex="0">
        <span i18n-title title="Edit Tag" i18n>🎨️</span> <label i18n>Edit Tag</label>
      </div>
    }
  </span>
  @if (home) {
    <div class="hide tag-header">
      @if (store.view.forYou) {
        <h3><a [routerLink]="['/home']" [queryParams]="{ forYou: true }" i18n>For You</a></h3>
        @if (!store.account.userSubs.length) {
          <p i18n>Follow other users to see their subscriptions alongside yours here.</p>
        }
      } @else {
        <h3><a [routerLink]="['/home']" i18n>Home</a></h3>
      }
    </div>
  }
  @if (!home && store.view.tag) {
    <div class="hide tag-header">
      @if (store.view.current === 'tag') {
        <h3>{{ ext?.name || tag }}</h3>
      }
      @if (store.account.signedIn && tag && admin.getTemplate('user')) {
        <div class="row center">
          @if (inBookmarks) {
            <button type="button"
                    class="bookmark"
                    i18n-title title="Remove from top bar"
                    (click)="removeBookmark()" i18n>&ndash; bookmark</button>
          } @else {
            <button type="button"
                    (click)="bookmark()"
                    i18n-title title="Add to top bar"
                    class="bookmark" i18n>+ bookmark</button>
          }
          @if (user) {
            @if (inSubs) {
              <button type="button"
                      class="subscribe"
                      i18n-title title="Remove from home screen"
                      (click)="unsubscribe()" i18n>&ndash; unfollow</button>
            } @else {
              <button type="button"
                      (click)="subscribe()"
                      i18n-title title="Add to home screen"
                      class="subscribe" i18n>+ follow</button>
            }
          } @else {
            @if (inSubs) {
              <button type="button"
                      class="subscribe"
                      i18n-title title="Remove from home screen"
                      (click)="unsubscribe()" i18n>&ndash; unsubscribe</button>
            } @else {
              <button type="button"
                      (click)="subscribe()"
                      i18n-title title="Add to home screen"
                      class="subscribe" i18n>+ subscribe</button>
            }
          }
          @if (inAlarms) {
            <button type="button"
                    class="alarm"
                    i18n-title title="Remove alarm"
                    (click)="removeAlarm()" i18n>🔇</button>
          } @else {
            <button type="button"
                    (click)="addAlarm()"
                    i18n-title title="Add alarm"
                    class="alarm" i18n>🔔</button>
          }
        </div>
      }
    </div>
  }
  @if (ui && ext) {
    <app-md class="hide ui"
            [disableSanitizer]="true"
            [origin]="ext.origin || ''"
            [text]="uiMarkdown"></app-md>
  }
  @if (root) {
    <div class="hide template-root">
      @if (ext?.config?.sidebar) {
        <app-md [origin]="ext?.origin || ''" [text]="ext!.config!.sidebar"></app-md>
      }
    </div>
  }
  @for (res of queryExts$ | async; track res) {
    <h5>{{ res.ext.name || res.ext.tag}} Tags:</h5>
    @for (c of res.children; track ext) {
      <app-ext [ext]="c" [useEditPage]="true"></app-ext>
    }
    @if (res.more) {
      <a [routerLink]="['/tags', res.ext.tag]"
         queryParamsHandling="merge"
         class="load-more no-select" i18n>
        load more
      </a>
    }
  }
  @if (user || home) {
    <div class="hide home">
      @if (bookmarkExts.length) {
        <div class="ext-text" i18n>
          My Bookmarks:
          <br>
          @for (book of bookmarkExts; track book; let i = $index) {
            <a class="tag" [routerLink]="['/tag', userConfig!.bookmarks![i]]" [appTitle]="book">{{ book.name || userConfig!.bookmarks![i] }}</a>
            <br>
          }
        </div>
      }
      @if (userSubExts.length) {
        <div class="ext-text" i18n>
          Following:
          <br>
          @for (sub of userSubExts; track sub; let i = $index) {
            <a class="tag" [routerLink]="['/tag', sub.tag + sub.origin]" [appTitle]="sub">{{ sub.name || (sub.tag + sub.origin) }}</a>
            <br>
          }
        </div>
      }
      @if (tagSubExts.length) {
        <div class="ext-text" i18n>
          Subscribed to:
          <br>
          @for (sub of tagSubExts; track sub; let i = $index) {
            <a class="tag" [routerLink]="['/tag', sub.tag + sub.origin]" [appTitle]="sub">{{ sub.name || (sub.tag + sub.origin) }}</a>
            <br>
          }
        </div>
      }
    </div>
  }
</ng-container>
