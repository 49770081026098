import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';
import { Mod } from '../model/tag';

export const threadPlugin: Plugin = {
  tag: 'plugin/thread',
  name: $localize`🧵️ Threads`,
  config: {
    type: 'plugin',
    default: true,
    genId: true,
    internal: true,
    reply: ['internal', 'plugin/thread'],
    responseButton: $localize`🧵️`,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    description: $localize`Attempt to merge similar Refs tagged plugin/thread into threads.`,
    aiInstructions: `# plugin/thread
    The plugin/thread tag indicates the Ref represents a comment in a threaded discussion.
    The first source (sources[0]) should be the immediate parent.
    The second source (sources[1]) should be the top of the thread.
    For top posts and first responses, including the top of the thread as both sources (sources[0] and sources[1])
    is recommended, as this will allow adding additional sources to be added without breaking threading.`,
    icons: [{ thumbnail: $localize`🧵️`, order: -1 }],
    filters: [
      { query: 'plugin/thread', label: $localize`🧵️ threads`, title: $localize`Discussion Threads or DMs`, group: $localize`Plugins 🧰️` },
    ],
  },
  generateMetadata: true,
};

export const threadMod: Mod = {
  plugin: [
    threadPlugin,
  ]
};
