@for (a of groupedActions | keyvalue; track a.key; let i = $index) {
  @if (i < actions - hiddenActions) {
    @if (a.value[0].confirm) {
      <app-confirm-action [action]="apply$(a.value)"
                          [appTitle]="a.value">{{ a.key }}</app-confirm-action>
    } @else {
      <app-inline-button [action]="apply$(a.value)"
                         [appTitle]="a.value">{{ a.key }}</app-inline-button>
    }
  }
}
@if (advanced || hiddenActions || mediaAttachment) {
  <span class="fake-link show-more"
        (click)="showAdvanced($event)">…</span>
  <ng-template #actionsMenu>
    <div class="context-menu advanced-actions" (click)="$event.stopPropagation()">
      @if (hiddenActions) {
        @for (a of groupedActions | keyvalue; track a.key; let i = $index) {
          @if (i >= actions - hiddenActions) {
            @if (a.value[0].confirm) {
              <app-confirm-action [action]="apply$(a.value)"
                                  [appTitle]="a.value">{{ a.key }}</app-confirm-action>
            } @else {
              <app-inline-button [action]="apply$(a.value)"
                                 [appTitle]="a.value">{{ a.key }}</app-inline-button>
            }
          }
        }
      }
      @if (showDownload) {
        <a class="fake-link"
           (click)="download()" i18n>download</a>
      }
      @if (mediaAttachment) {
      <a *ngIf="mediaAttachment"
         class="fake-link"
         (click)="downloadMedia()" i18n>media</a>
      }
      @if (groupedAdvancedActions) {
        @for (a of groupedAdvancedActions | keyvalue; track a.key) {
          @if (a.value[0].confirm) {
            <app-confirm-action [message]="a.value[0].confirm!"
                                [action]="apply$(a.value)"
                                [appTitle]="a.value">{{ a.key }}</app-confirm-action>
          } @else {
            <app-inline-button [action]="apply$(a.value)"
                               [appTitle]="a.value">{{ a.key }}</app-inline-button>
          }
        }
      }
    </div>
  </ng-template>
} @else if (showDownload) {
  <a class="fake-link"
     (click)="download()" i18n>download</a>
}
