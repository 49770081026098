@if (passwordForm) {
  <form class="form"
        [formGroup]="passwordForm"
        (ngSubmit)="save()">
    <label for="password" i18n>Password:</label>
    <input id="password" type="password" formControlName="password">

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
      <span class="buttons right">
      <button type="submit" [disabled]="submitted && !passwordForm.valid" i18n>Save</button>
    </span>
  </form>
}
