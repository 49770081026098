import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';
import { Mod } from '../model/tag';

const secretPlugin: Plugin = {
  tag: '+plugin/secret',
  name: $localize`🔑️ Secret`,
  config: {
    mod: $localize`🔑️ Secret`,
    default: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    settings: $localize`secrets`,
    submit: $localize`🔑️ secret`,
    internal: true,
    genId: true,
    // TODO: submit as private
    icons: [{ label: $localize`🔑️`, order: 3 }],
    filters: [
      { query: '+plugin/secret', label: $localize`🔑️ secret`, title: $localize`Secrets with opaque tags`, group: $localize`Plugins 🧰️` },
    ],
    description: $localize`Store secrets with opaque protected tags.`,
  },
};

export const secretMod: Mod = {
  plugin: [
    secretPlugin,
  ],
}
