<ng-container *mobxAutorun>
  @if (plugin) {
    <div class="row form">
      <span><!-- Title --></span>
      <h2 i18n>{{ plugin.name || plugin.tag }} Settings</h2>
      <span><!-- Description --></span>
      <p>{{ plugin.config?.description || '' }}</p>

      <span><!-- Buttons --></span>
      <span class="buttons right">
        @if (writeAccess) {
          @if (plugin.config?.hasDefaults) {
            <button type="button" (click)="loadDefaults()" i18n>🔁️ load defaults</button>
          }
          @if (plugin.config?.hasClearCache) {
            <button type="button" (click)="clearCache()">{{ plugin.config!.hasClearCache }}</button>
          }
        }
    </span>
    </div>
  }
  @if (query.error?.status !== 403) {
    <app-ref-list [page]="query.page"></app-ref-list>
  } @else {
    <div class="error-403" i18n>Access Denied</div>
  }
</ng-container>
