<div class="container"
     *mobxAutorun
     [class.debug]="debug"
     [class.offline]="store.offline"
     (dragover)="dragOver($event)"
     (drop)="drop($event)">
  @if (store.offline) {
    <div class="offline-banner">📴️ Offline</div>
  } @else {
    <app-login-popup></app-login-popup>
  }
  <app-subscription-bar></app-subscription-bar>
  <router-outlet></router-outlet>
  @if (store.account.banned) {
    <div class="ban-message">
      You have been banned.
    </div>
  }
  <div class="spacer"></div>
  <footer>
    <hr>
    <div class="row">
      <p class="version stretch" i18n>
        Powered by Jasper <a [href]="website">{{ config.version || '' }}</a>
      </p>
      @if (store.eventBus.progressDen) {
        <div class="log stretch">
          @if (store.eventBus.progressDen > 1) {
            <progress [value]="store.eventBus.progressNum" [max]="store.eventBus.progressDen"></progress>
          }
          @for (msg of store.eventBus.progressMessages; track msg) {
            <div>{{ msg }}</div>
          }
          <button type="button" (click)="store.eventBus.clearProgress()">clear</button>
        </div>
      }
    </div>
  </footer>
</div>
