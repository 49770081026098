import { DateTime } from 'luxon';
import { Plugin } from '../model/plugin';
import { Mod } from '../model/tag';

export const tablePlugin: Plugin = {
  tag: 'plugin/table',
  name: '📏️ Table',
  config: {
    type: 'plugin',
    experimental: true,
    submitText: true,
    editingViewer: true,
    generated: $localize`Generated by jasper-ui ${DateTime.now().toISO()}`,
    description: $localize`Activates built-in table support and allows users to create tables.`,
    aiInstructions: `# plugin/table
    If the plugin/table tag is in the tag list, the Ref comment should be CSV instead of Markdown.`,
    icons: [{ label: $localize`📏️`, order: 2 }],
    filters: [
      { query: 'plugin/table', label: $localize`📏️ table`, title: $localize`Spreadsheets`, group: $localize`Plugins 🧰️` },
    ],
  },
};

export const tableMod: Mod = {
  plugin: [
    tablePlugin,
  ]
};
