@for (p of pieces; track i; let i = $index) {
  <div class="tile {{ color(i) }}"
       [class.from]="getCoord(white ? i : 63 - i) === from"
       [class.move]="moves.includes(getCoord(white ? i : 63 - i))"
       (click)="clickSquare(white ? i : 63 - i)"
       cdkDropList
       [cdkDropListData]="white ? i : 63 - i"
       (cdkDropListDropped)="drop($any($event))">
    @if (white ? p : pieces[63-i]; as rp) {
      <div class="chess-piece {{ rp?.color }}"
           [class.bounce]="bounce.includes(rp.square)"
           [class.disabled]="!writeAccess || turn !== rp.color"
           cdkDrag
           cdkDragBoundary=".chess-board"
           (cdkDragStarted)="setPieceSize()"
           [cdkDragData]="rp"
           [cdkDragDisabled]="config.mobile || turn !== rp.color">{{ drawPiece(rp) }}</div>
    }
  </div>
}
