<ng-container *mobxAutorun>
  <app-loading *ngIf="!error && !ext else loaded"></app-loading>
  <ng-template #loaded>
    <div *ngIf="ext else error"
         class="kanban"
         cdkDropListGroup
         cdkScrollable
         [class.single-column]="(columns.length || 0) + (showColumnBacklog ? 1 : 0) === 1"
         [class.swimlanes]="swimLanes"
         [style.--cols]="columns.length + (showColumnBacklog ? 1 : 0)"
         [class.compact]="store.view.floatingSidebar && store.view.sidebarExpanded">
      <div class="kanban-header"
           style="max-width: 90vw"
           cdkDropList
           [cdkDropListData]="{col: '-'+ext.tag}"
           (cdkDropListDropped)="drop($any($event))"
           [style.grid-column]="'1 / ' + (1 + (swimLanes ? 1 : 0) + (showColumnBacklog ? 1 : 0) + columns.length)">
        <div class="kanban-options">
          <h2><a [routerLink]="['/tag', ext.tag + ext.origin]" [appTitle]="ext">{{ ext.name || ext.tag }}</a></h2>
          <div *ngIf="pageControls">
            <div *ngIf="kanbanConfig.swimLanes?.length"
                 class="disable-swim-lanes">
              <input id="disable-swim-lanes"
                     type="checkbox"
                     [(ngModel)]="disableSwimLanes">
              <label for="disable-swim-lanes" i18n>Hide Swim Lanes</label>
            </div>
            <app-page-controls [hideCols]="true"></app-page-controls>
          </div>
        </div>
        <div class="kanban-remove">🗑️</div>
      </div>
      <div *ngIf="swimLanes"><!-- corner spacer --></div>
      <a *ngIf="showColumnBacklog"
         class="column-title tag"
         (click)="bookmarks.toggleQuery(store.hotkey ? '!(' + colBacklog + ')' : colBacklog)"
         title="No matching column tag"><span class="filter-toggle">{{ kanbanConfig.columnBacklogTitle || 'Backlog' }}</span></a>
      <a *ngFor="let col of columns trackBy: trackByIdx"
         class="column-title tag"
         (click)="bookmarks.toggleQuery(store.hotkey ? '!(' + col + ')' : col)"
         [title]="col"><span class="filter-toggle">{{ (exts.getCachedExt(col, ext.origin || '') | async)?.name || col }}</span></a>
      <ng-container *ngIf="swimLanes else noSwimLanes">
        <ng-container *ngFor="let sl of swimLanes trackBy: trackByIdx">
          <a class="swim-lane-title tag"
             (click)="bookmarks.toggleQuery(store.hotkey ? '!(' + sl + ')' : sl)"
             [title]="sl"><span class="filter-toggle">{{ (exts.getCachedExt(sl, ext.origin || '') | async)?.name || sl }}</span></a>
          <app-kanban-column *ngIf="showColumnBacklog"
                             cdkDropList
                             cdkScrollable
                             [ext]="ext"
                             [size]="size"
                             [sort]="sort"
                             [filter]="filter"
                             [search]="search"
                             [hideSwimLanes]="disableSwimLanes"
                             [cdkDropListData]="{sl}"
                             (cdkDropListDropped)="drop($any($event))"
                             [updates]="updates"
                             [query]="getQuery({sl})"
                             [addTags]="addingTags({sl})"></app-kanban-column>
          <app-kanban-column *ngFor="let col of columns trackBy: trackByIdx"
                             cdkDropList
                             cdkScrollable
                             [ext]="ext"
                             [size]="size"
                             [sort]="sort"
                             [filter]="filter"
                             [search]="search"
                             [hideSwimLanes]="disableSwimLanes"
                             [cdkDropListData]="{col, sl}"
                             (cdkDropListDropped)="drop($any($event))"
                             [updates]="updates"
                             [query]="getQuery({col, sl})"
                             [addTags]="addingTags({col, sl})"></app-kanban-column>
        </ng-container>
        <ng-container *ngIf="showSwimLaneBacklog">
          <a class="swim-lane-title tag"
             (click)="bookmarks.toggleQuery(store.hotkey ? '!(' + slBacklog + ')' : slBacklog)"
             title="No matching swim lane"><span class="filter-toggle">{{ kanbanConfig.swimLaneBacklogTitle || 'Backlog' }}</span></a>
          <app-kanban-column *ngIf="showColumnBacklog"
                             cdkDropList
                             cdkScrollable
                             [ext]="ext"
                             [size]="size"
                             [sort]="sort"
                             [filter]="filter"
                             [search]="search"
                             [hideSwimLanes]="disableSwimLanes"
                             [cdkDropListData]="{}"
                             (cdkDropListDropped)="drop($any($event))"
                             [updates]="updates"
                             [query]="getQuery({})"
                             [addTags]="addingTags({})"></app-kanban-column>
          <app-kanban-column *ngFor="let col of columns trackBy: trackByIdx"
                             cdkDropList
                             cdkScrollable
                             [ext]="ext"
                             [size]="size"
                             [sort]="sort"
                             [filter]="filter"
                             [search]="search"
                             [hideSwimLanes]="disableSwimLanes"
                             [cdkDropListData]="{col}"
                             (cdkDropListDropped)="drop($any($event))"
                             [updates]="updates"
                             [query]="getQuery({col})"
                             [addTags]="addingTags({col})"></app-kanban-column>
        </ng-container>
      </ng-container>
      <ng-template #noSwimLanes>
        <app-kanban-column *ngIf="showColumnBacklog"
                           cdkDropList
                           cdkScrollable
                           [ext]="ext"
                           [size]="size"
                           [sort]="sort"
                           [filter]="filter"
                           [search]="search"
                           [hideSwimLanes]="disableSwimLanes"
                           [cdkDropListData]="{}"
                           (cdkDropListDropped)="drop($any($event))"
                           [updates]="updates"
                           [query]="getQuery({})"
                           [addTags]="addingTags({})"></app-kanban-column>
        <app-kanban-column *ngFor="let col of columns trackBy: trackByIdx"
                           cdkDropList
                           cdkScrollable
                           [ext]="ext"
                           [size]="size"
                           [sort]="sort"
                           [filter]="filter"
                           [search]="search"
                           [hideSwimLanes]="disableSwimLanes"
                           [cdkDropListData]="{col}"
                           (cdkDropListDropped)="drop($any($event))"
                           [updates]="updates"
                           [query]="getQuery({col})"
                           [addTags]="addingTags({col})"></app-kanban-column>
      </ng-template>
    </div>
    <ng-template #error>
      <div class="error-404" i18n>Not Found</div>
    </ng-template>
  </ng-template>
  <app-page-controls *ngIf="pageControls"></app-page-controls>
</ng-container>
