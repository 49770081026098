<app-loading *ngIf="!ref.modified"></app-loading>
<div class="kanban-card-container">
  <div class="badges">
    <!-- TODO: Check if local version is mostly identical -->
    <a class="badge"
       *ngIf="remote"
       (click)="copy()"
       i18n-title title="Copy remote ref">📥️</a>
    <ng-container *ngIf="badgeExts$ | async as badgeExts">
      <a class="badge filter-toggle"
         *ngFor="let e of badgeExts"
         [class.filtering]="bookmarks.filters.includes($any('query/' + e.tag))"
         (click)="bookmarks.toggleQuery(store.hotkey ? '!(' + e.tag + ')' : e.tag)"
         [title]="e.name && e.tag">{{ e.name || e.tag }}</a>
    </ng-container>
    <a class="badge"
       *ngIf="dependents"
       (click)="bookmarks.toggleSources(ref.url)"
       i18n-title title="{{ dependents }} Dependents">⤴️</a>
    <a class="badge"
       *ngIf="dependencies"
       (click)="bookmarks.toggleResponses(ref.url)"
       i18n-title title="{{ dependencies }} Dependencies">⤵️</a>
    <a class="badge"
       *ngIf="threads"
       (focus)="store.view.setLastSelected(ref)"
       (pointerdown)="store.view.setLastSelected(ref)"
       [routerLink]="['/ref', thread, 'thread']"
       i18n-title title="Thread ({{ threads }} replies)">🧵️</a>
    <a class="badge"
       *ngIf="comments"
       [routerLink]="['/ref', ref.url, 'comments']"
       i18n-title title="{{ comments }} Comments">💬️</a>
  </div>
  <div class="row">
    @if (thumbnail) {
      <div class="thumbnail"
           [style.background-image]="[repostRef, ref] | thumbnail | async | cssUrl"
           [style.background-color]="thumbnailColor"
           [style.border-radius]="thumbnailRadius">{{ thumbnailEmoji }}</div>
    }
    <a class="card-title grow" [routerLink]="['/ref', ref.url]">{{ title }}</a>
  </div>
  <app-chess *ngIf="chess"
             [white]="chessWhite"
             [ref]="ref"></app-chess>
  <app-todo *ngIf="todo"
             [ref]="ref"></app-todo>
  <app-md *ngIf="currentText"
          [origin]="ref.origin || ''"
          [plugins]="ref.tags || []"
          [text]="currentText"></app-md>

  <ng-template #cardMenu>
    <div class="context-menu" (click)="$event.stopPropagation()">
      <span i18n>Toggle Badges</span>
      <ng-container *ngIf="allBadgeExts$ | async as badgeExts; else allBadgesNoExts">
        <ng-container *ngFor="let e of badgeExts">
          <div (contextmenu)="$event.preventDefault()"
               (pointerdown)="autoClose = false"
               (pointerup)="toggleBadge(e.tag, $event)">
            <input [id]="e.tag"
                   type="checkbox"
                   [checked]="ref.tags?.includes(e.tag)">
            <label [for]="e.tag"
                   [title]="e.name && e.tag" i18n>{{ e.name || e.tag }}</label>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #allBadgesNoExts>
        <ng-container *ngFor="let b of ext?.config?.badges || [],">
          <div (contextmenu)="$event.preventDefault()"
               (pointerdown)="autoClose = false"
               (pointerup)="toggleBadge(b, $event)">
            <input [id]="b"
                   type="checkbox"
                   [checked]="ref.tags?.includes(b)">
            <label [for]="b" i18n>{{ b }}</label>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </ng-template>
</div>
