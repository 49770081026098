<ng-container *mobxAutorun>
  <form class="form" [formGroup]="webForm" (ngSubmit)="submit()"
        [appLimitWidth]="refForm?.fill?.nativeElement" [limitSibling]="true">

    <app-ref-form [group]="webForm"
                  (toggleTag)="bookmarks.toggleTag($event)"></app-ref-form>

    @for (e of serverError; track e) {
      <span><!-- Unexpected Error --></span>
      <div class="error">{{ e }}</div>
    }

    <span><!-- Buttons --></span>
    <span class="buttons right">
      @if (submitting) {
        <app-loading [inline]="true"></app-loading>
      }
      <button type="submit" [disabled]="submitting || submitted && !webForm.valid" i18n>Submit</button>
    </span>

  </form>
</ng-container>
