<div class="list-container">
  @for (id of list; track id; let i = $index) {
    <div class="list-number">{{ i + 1 }}</div>
    <app-backup [id]="id"
                [origin]="origin"></app-backup>
  }
</div>
@if (!list) {
  <app-loading></app-loading>
} @else if (list.length === 0) {
  <p class="no-results">
    No results found
  </p>
}
