<div class="todo-item-container row">
  <input #checkbox type="checkbox" [checked]="checked" (input)="toggle()">
  @if (editing) {
    <textarea [value]="text"
              (focusin)="editing = true"
              (input)="text = $any($event).target.value"
              (keydown)="$event.key === 'Enter' && edit() || true"
              (blur)="edit()"
              [appAutofocus]="true"></textarea>
  } @else if (text) {
    <app-md [origin]="origin"
            [plugins]="plugins"
            [text]="text"
            tabindex="0"
            (mouseover)="hovering = true"
            (mouseout)="hovering = false"
            (focus)="editing = !config.mobile && !hovering"
            (click)="editing = true"></app-md>
  }
</div>
